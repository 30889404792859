import CategoryTiles from 'components/PlaylistCategory/CategoryTiles';
import Head from './Head';
import PageBody, { ViewName } from 'views/PageBody';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlaylistCategory from 'components/PlaylistCategory';
import usePageView from 'modules/Analytics/usePageView';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import {
  PlaylistCategory as Category,
  CategoryTitle,
} from 'components/PlaylistCategory/primitives';
import { formatPlaylistRecs } from 'state/Playlist/helpers';
import {
  getAllAccessPreview,
  getIsTrialEligible,
  getSubscriptionType,
} from 'state/Entitlements/selectors';
import { getPlaylistRecs as getPlaylistRecsAction } from 'state/Playlist/actions';
import { getRecommendedPlaylistRecs } from 'state/Features/selectors';
import { getPlaylistRecs as getUserPlaylistRecs } from 'state/Recs/selectors';
import { Rec } from 'state/Recs/types';
import { SEED_CATEGORIES } from 'state/PlaylistDirectory/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

function PlaylistDirectory() {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const recommendedRecs: Array<Rec> = useSelector(getUserPlaylistRecs);

  useEffect(() => {
    dispatch(getPlaylistRecsAction());
  }, []);

  const allAccessPreview = useSelector(getAllAccessPreview);
  const isTrialEligible = useSelector(getIsTrialEligible);
  const subscriptionType = useSelector(getSubscriptionType);
  const recommendedPlaylistFlag = useSelector(getRecommendedPlaylistRecs);

  usePageView({ pageName: 'playlist_directory' });

  const recommendedData =
    recommendedPlaylistFlag && recommendedRecs.length > 5 ? (
      <Category data-test="recommendation">
        <CategoryTitle>{translate('Recommended For You')}</CategoryTitle>
        <CategoryTiles
          allAccessPreview={allAccessPreview}
          aspectRatio={1}
          isTrialEligible={isTrialEligible}
          itemSelectedLocation="playlist_directory|recommended|tile"
          playedFrom={PLAYED_FROM.DIR_PLAYLIST_MAIN_FEATURED}
          showAllTiles={false}
          subscriptionType={subscriptionType}
          subtitleLines={2}
          tiles={formatPlaylistRecs(recommendedRecs)}
          tilesInRow={4}
          titleLines={1}
          useBareImage
        />
      </Category>
    ) : null;

  return (
    <>
      <Head />
      <PageBody
        dataTest={ViewName.PlaylistDirectory}
        mainStyles={{ overflowX: 'hidden' }}
      >
        {recommendedData}
        <PlaylistCategory
          category={SEED_CATEGORIES.MOODS_ACTIVITIES}
          dataTest="moods-playlists"
          itemSelectedLocation="playlist_directory|perfect_for|tile"
          name={translate('Moods & Activities')}
          playedFrom={PLAYED_FROM.DIR_PLAYLIST_MAIN_FEATURED}
          tilesInRow={4}
        />
        <PlaylistCategory
          category={SEED_CATEGORIES.DECADES}
          dataTest="decades-playlists"
          itemSelectedLocation="playlist_directory|decades|tile"
          name={translate('Decades')}
          playedFrom={PLAYED_FROM.DIR_PLAYLIST_MAIN_FEATURED}
          tilesInRow={4}
        />
        <PlaylistCategory
          category={SEED_CATEGORIES.FEATURED}
          dataTest="featured-playlists"
          itemSelectedLocation="playlist_directory|featured|tile"
          name={translate('Featured Playlists')}
          playedFrom={PLAYED_FROM.DIR_PLAYLIST_MAIN_FEATURED}
          subtitleLines={2}
          tilesInRow={4}
          titleLines={1}
        />
        <PlaylistCategory
          aspectRatio={16 / 9}
          category={SEED_CATEGORIES.GENRE}
          dataTest="genre-playlists"
          itemSelectedLocation="playlist_directory|genre_playlists|tile"
          name={translate('Genres')}
          playedFrom={PLAYED_FROM.DIR_PLAYLIST_MAIN_FEATURED}
          showAllTiles
          tilesInRow={3}
        />
      </PageBody>
    </>
  );
}

export default PlaylistDirectory;
